.formated-text {
  p {
    @apply py-1;
  }

  h1 {
    @apply font-bold text-2xl py-2;
  }

  h2 {
    @apply font-bold text-2xl py-2;
  }

  h3 {
    @apply font-bold py-1;
  }

  h4 {
    @apply font-semibold;
  }

  ul {
    @apply list-disc;
  }
}
